import * as React from "react";
import styled from "styled-components";

const SvgFacebook16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 100" {...props}>
    <path
      d="M81.25 0A18.74 18.74 0 01100 18.75v62.5A18.74 18.74 0 0181.25 100H62.42V64.12h11.3L75 50.91h-1.17V51H62.75v-7.4c.85-7 12.25-5.69 12.25-5.69v-12l-1.47-.3c-5.12-.9-22-2.67-25.8 12v.1c-.75 2.15-.64 12-.64 13.22H37.5v13.19h10.12V100H18.75A18.74 18.74 0 010 81.25v-62.5A18.74 18.74 0 0118.75 0z"
      fill="#1d1d1b"
      fillRule="evenodd"
    />
  </svg>
);

export default styled(SvgFacebook16)``;
