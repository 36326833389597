import * as React from "react";
import styled from "styled-components";

const SvgIos24 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 83.33 100" {...props}>
    <path d="M62.39 0A24.68 24.68 0 0157 17.29c-3.59 4.58-9.48 8.14-15.25 7.67-.75-6.22 2.18-12.7 5.5-16.76C51 3.63 57.22.22 62.39 0zm-1.65 25l2 .11.93.09.51.06 1.12.17a23.49 23.49 0 0115.38 9.43l-.89.56-.67.46-.8.6c-3.28 2.57-8.78 8.2-8.69 17.2A21.36 21.36 0 0080.5 72.26l.84.47.72.38 1 .46.27.11-.19.59-.36 1-.64 1.64-.56 1.33-.68 1.49a54 54 0 01-4.66 8L74.63 90l-1.09 1.47c-3.52 4.55-7.43 8.33-13 8.43a16.36 16.36 0 01-6.82-1.4l-2.54-1a19.3 19.3 0 00-7.53-1.49 21.22 21.22 0 00-8.47 1.76l-2.53 1a17 17 0 01-5.73 1.23c-5.11.19-9.3-3.66-12.9-8.16L12.9 90.4l-1.08-1.46-1.05-1.45C2 75.22-4.74 52.82 4.28 37.7a25.19 25.19 0 0121.17-12.39 24.7 24.7 0 018.83 1.89l3.36 1.27a14.57 14.57 0 004.7 1.14 14.48 14.48 0 004.51-1.13l3.82-1.43A27.78 27.78 0 0160.74 25z" />
  </svg>
);

export default styled(SvgIos24)``;
