import * as React from "react";
import styled from "styled-components";

const SvgCross10 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 100" {...props}>
    <path
      d="M2.88 2.88a9.83 9.83 0 0113-.82l.93.82L50 36.1 83.22 2.88a9.83 9.83 0 0113.9 13.9L63.9 50l33.22 33.22a9.84 9.84 0 01.82 13l-.82.92a9.83 9.83 0 01-13 .82l-.93-.82L50 63.9 16.78 97.12a9.83 9.83 0 01-13.9-13.9L36.1 50 2.88 16.78a9.84 9.84 0 01-.82-13z"
      fill="#1d1d1b"
      fillRule="evenodd"
    />
  </svg>
);

export default styled(SvgCross10)``;
