import * as React from "react";
import styled from "styled-components";

const SvgAndroid24 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 83.33 100" {...props}>
    <path d="M54.22 16.24a2.66 2.66 0 102.61 2.66 2.63 2.63 0 00-2.61-2.66zm-25.11 0a2.66 2.66 0 102.61 2.66 2.63 2.63 0 00-2.61-2.66zM58 .45a.94.94 0 011.36-.29 1 1 0 01.31 1.33L55.1 8.71c8.2 4.11 13.73 11.86 13.73 20.75 0 .47 0 .93-.06 1.39v.23H14.59c-.05-.54-.08-1.07-.08-1.62 0-8.8 5.42-16.46 13.49-20.63l.25-.12-4.56-7.18A1 1 0 0124 .16a1 1 0 011.32.25L30 7.88a30.42 30.42 0 0123.13-.08l.19.08zm19.51 32.16a5.91 5.91 0 015.82 5.86v26.05a5.92 5.92 0 01-5.72 6h-.35a5.92 5.92 0 01-5.83-5.87V38.57a5.92 5.92 0 015.74-6h.34zm-71.44 0a5.91 5.91 0 015.83 5.86v26.05a5.92 5.92 0 01-5.73 6h-.35A5.91 5.91 0 010 64.62V38.57a5.91 5.91 0 015.73-6h.34zm62.57 1v41A5.42 5.42 0 0163.38 80h-5.81v14a5.91 5.91 0 01-5.73 6h-.35a5.92 5.92 0 01-5.83-5.87V80h-8v14a5.91 5.91 0 01-5.74 6h-.34a5.91 5.91 0 01-5.83-5.87V80H20a5.43 5.43 0 01-5.35-5.38V33.56z" />
  </svg>
);

export default styled(SvgAndroid24)``;
