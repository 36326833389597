import * as React from "react";
import styled from "styled-components";

const SvgYoutube16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 87.5" {...props}>
    <path
      d="M37.5 62.5V25l31.25 18.75zM90 2.19C87 1.09 68.5 0 50 0S13 1.09 10 2.19C2 4.92 0 24.06 0 43.75s2 38.83 10 41.56c3 1.1 21.5 2.19 40 2.19s37-1.09 40-2.19c8-2.73 10-21.87 10-41.56S98 4.92 90 2.19z"
      fill="#1d1d1b"
    />
  </svg>
);

export default styled(SvgYoutube16)``;
