import * as React from "react";
import styled from "styled-components";

const SvgTablet50 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 68" {...props}>
    <path d="M10 32a2 2 0 11-2 2 2 2 0 012-2zM88.35 4l.3.34c1.66 1.96 3.23 4.79 3.35 7.33v44.66C91.87 59 90.19 62 88.35 64A9 9 0 0092 63a6.78 6.78 0 002.9-2.81c.8-1.45 1.1-2.87 1.13-6.61V15c0-4.16-.29-5.64-1.13-7.16A6.78 6.78 0 0092 5a8.77 8.77 0 00-3.65-1zm-5.76-.13H13.68C10.67 4 9.36 4.29 8 5a6.78 6.78 0 00-2.87 2.8C4.33 9.25 4 10.67 4 14.41V54.7c.08 2.93.4 4.2 1.11 5.5A6.78 6.78 0 008 63c1.49.78 2.94 1.07 6.79 1.1h67.79l2.49-2.42.31-.32a9.37 9.37 0 002.62-5V11.67c-.1-1.76-1.41-3.73-2.62-5l-.31-.32zM14.76 0h69.86c5.38 0 7.29.54 9.24 1.56A10.79 10.79 0 0198.4 6c1 1.9 1.6 3.79 1.6 9v39.23c-.08 4.32-.63 6.05-1.59 7.8a10.79 10.79 0 01-4.54 4.41c-2 1-3.89 1.56-9.24 1.56H14.16c-4.44-.08-6.22-.61-8-1.55A10.79 10.79 0 011.6 62l-.25-.48C.51 59.87 0 58 0 53.65V15c0-5.2.56-7.09 1.6-9a10.79 10.79 0 014.54-4.44l.49-.25C8.36.49 10.3 0 14.76 0z" />
  </svg>
);

export default styled(SvgTablet50)``;
