import * as React from "react";
import styled from "styled-components";

const SvgTwitter16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 78" {...props}>
    <path
      d="M31.45 78c37.73 0 58.37-30 58.37-56 0-.85 0-1.7-.06-2.54A40.68 40.68 0 00100 9.22a42 42 0 01-11.78 3.1 20 20 0 009-10.89 42.36 42.36 0 01-13 4.78 21.18 21.18 0 00-24.66-3.88 19.46 19.46 0 00-10.32 21.84A59.07 59.07 0 017 3.59a19.24 19.24 0 006.35 26.29A20.93 20.93 0 014 27.42v.25A19.93 19.93 0 0020.46 47a21.28 21.28 0 01-9.26.33A20.44 20.44 0 0030.36 61a42.27 42.27 0 01-25.48 8.44A44.22 44.22 0 010 69.15 59.81 59.81 0 0031.45 78"
      fill="#1d1d1b"
    />
  </svg>
);

export default styled(SvgTwitter16)``;
