import * as React from "react";
import styled from "styled-components";

const SvgQuestion20 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 74 100" {...props}>
    <path d="M37.54 0a39.75 39.75 0 00-15.8 3 39 39 0 00-11.68 7.53 33.81 33.81 0 00-7.36 10A26 26 0 000 30.85a3 3 0 00.88 2.25l.62.47.7.28.77.09h16.48l.95-.07A3.15 3.15 0 0022.89 32l.62-1.3L25 27.89a12.56 12.56 0 013.85-4.16q2.64-1.9 8.71-1.9c4.14 0 7.2.78 9.18 2.32a7.7 7.7 0 013 6.41 8.37 8.37 0 01-1.82 5.21 30.93 30.93 0 01-4.52 4.72l-1.86 1.58-4 3.28a48.29 48.29 0 00-5.87 5.57 30.07 30.07 0 00-4.53 6.69 18.46 18.46 0 00-1.82 8.31 3.92 3.92 0 002.26 3.52l.67.21.72.07h16.4l.71-.07a2.83 2.83 0 001.66-1.06 6.81 6.81 0 001-1.69l.81-2.5a14.42 14.42 0 012.55-4.19 47.08 47.08 0 015-5q2.78-2.39 5.74-4.86a38.89 38.89 0 005.47-5.56 29.14 29.14 0 004.12-6.9A22.55 22.55 0 0074 29a24.22 24.22 0 00-2.84-11.62 28.94 28.94 0 00-7.76-9.14 37.63 37.63 0 00-11.61-6.06A45.81 45.81 0 0037.54 0zm8.1 78.17h-17l-.72.07a3.47 3.47 0 00-1.85 1.06 3.76 3.76 0 00-.81 1.23l-.2.69L25 82v14.2l.07.75a3.67 3.67 0 001 1.92 3.76 3.76 0 001.18.85l.67.21.72.07h17l.72-.07a3.47 3.47 0 001.85-1.06 3.76 3.76 0 00.79-1.23l.2-.69.07-.75V82l-.07-.75a3.67 3.67 0 00-1-1.92 3.76 3.76 0 00-1.2-.88l-.67-.21z" />
  </svg>
);

export default styled(SvgQuestion20)``;
