import * as React from "react";
import styled from "styled-components";

const SvgArrowRight10 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 59.98 100" {...props}>
    <path d="M56.94 57.84l-39.1 39.1A10.45 10.45 0 013.06 82.16L35.23 50 3.06 17.84A10.45 10.45 0 012.19 4l.87-1a10.44 10.44 0 0113.79-.87l1 .87 39.1 39.1a10.42 10.42 0 013 6.78V51a10.42 10.42 0 01-3.01 6.84z" />
  </svg>
);

export default styled(SvgArrowRight10)``;
