import * as React from "react";
import styled from "styled-components";

const SvgDesktop50 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 88" {...props}>
    <path d="M50 8a2 2 0 11-2 2 2 2 0 012-2zm36-4H14a10 10 0 00-10 9.57V59l2.93 2.2a14 14 0 007.8 2.8h69.94a14 14 0 007.91-2.45l.49-.35L96 59V14A10 10 0 0086 4zm9.76 60.18l-.29.22A18 18 0 0185.35 68h-70a18 18 0 01-10.26-3.2l-.56-.4-.29-.22A10 10 0 0013.57 72H86a10 10 0 009.76-7.82zM58 76H42v8h16zM88 0a12 12 0 0112 12v52a12 12 0 01-12 12H62v8h2a2 2 0 010 4H36a2 2 0 010-4h2v-8H12A12 12 0 010 64V12A12 12 0 0112 0z" />
  </svg>
);

export default styled(SvgDesktop50)``;
