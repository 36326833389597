import * as React from "react";
import styled from "styled-components";

const SvgSeparator80 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={80} height={30} {...props}>
    <g fill="none" fillRule="evenodd" fillOpacity={0.1}>
      <path
        d="M67.992.645c2.317 1.048 4.528 2.625 6.633 4.73 1.973 1.974 3.482 4.04 4.527 6.2l.203.433a7.26 7.26 0 010 5.984c-1.048 2.317-2.625 4.528-4.73 6.633-1.974 1.973-4.04 3.482-6.2 4.527l-.433.203a7.26 7.26 0 01-5.984 0c-2.317-1.048-4.528-2.625-6.633-4.73-1.973-1.974-3.482-4.04-4.527-6.2l-.203-.433a7.26 7.26 0 010-5.984c1.048-2.317 2.625-4.528 4.73-6.633 1.974-1.973 4.04-3.482 6.2-4.527l.433-.203a7.26 7.26 0 015.984 0z"
        fill="#FB6EBA"
      />
      <path
        d="M42.992.645c2.317 1.048 4.528 2.625 6.633 4.73 1.973 1.974 3.482 4.04 4.527 6.2l.203.433a7.26 7.26 0 010 5.984c-1.048 2.317-2.625 4.528-4.73 6.633-1.974 1.973-4.04 3.482-6.2 4.527l-.433.203a7.26 7.26 0 01-5.984 0c-2.317-1.048-4.528-2.625-6.633-4.73-1.973-1.974-3.482-4.04-4.527-6.2l-.203-.433a7.26 7.26 0 010-5.984c1.048-2.317 2.625-4.528 4.73-6.633 1.974-1.973 4.04-3.482 6.2-4.527l.433-.203a7.26 7.26 0 015.984 0z"
        fill="#19A0FF"
      />
      <path
        d="M17.992.645c2.317 1.048 4.528 2.625 6.633 4.73 1.973 1.974 3.482 4.04 4.527 6.2l.203.433a7.26 7.26 0 010 5.984c-1.048 2.317-2.625 4.528-4.73 6.633-1.974 1.973-4.04 3.482-6.2 4.527l-.433.203a7.26 7.26 0 01-5.984 0c-2.317-1.048-4.528-2.625-6.633-4.73-1.973-1.974-3.482-4.04-4.527-6.2l-.203-.433a7.26 7.26 0 010-5.984C1.693 9.69 3.27 7.48 5.375 5.375c1.974-1.973 4.04-3.482 6.2-4.527l.433-.203a7.26 7.26 0 015.984 0z"
        fill="#FF9085"
      />
    </g>
  </svg>
);

export default styled(SvgSeparator80)``;
