import * as React from "react";
import styled from "styled-components";

const SvgMagnify16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 100" {...props}>
    <path
      d="M43.75 12.5A31.25 31.25 0 1075 43.75 31.25 31.25 0 0043.75 12.5zm0-12.5A43.75 43.75 0 0179 69.69l19.1 19.09a6.58 6.58 0 11-9.3 9.3L69.69 79A43.75 43.75 0 1143.75 0z"
      fill="#1d1d1b"
    />
  </svg>
);

export default styled(SvgMagnify16)``;
