export { fontSize, fontFace, fontFamily } from "./cssUtils";

const interpolateDefault = (v: any) => (v && v.default) || v;

export const product_sans = {
  regular: {
    name: "product_sansregular",
    woff: interpolateDefault(
      require("./fonts/product_sans/product_sans_regular-webfont.woff"),
    ),
    woff2: interpolateDefault(
      require("./fonts/product_sans/product_sans_regular-webfont.woff2"),
    ),
  },

  medium: {
    name: "product_sans_mediumregular",
    woff: interpolateDefault(
      require("./fonts/product_sans/productsans-medium-webfont.woff"),
    ),
    woff2: interpolateDefault(
      require("./fonts/product_sans/productsans-medium-webfont.woff2"),
    ),
  },

  bold: {
    name: "product_sansbold",
    woff: interpolateDefault(
      require("./fonts/product_sans/product_sans_bold-webfont.woff"),
    ),
    woff2: interpolateDefault(
      require("./fonts/product_sans/product_sans_bold-webfont.woff2"),
    ),
  },
};
