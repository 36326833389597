import * as React from "react";
import styled from "styled-components";

const SvgInstagram16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 100" {...props}>
    <path
      d="M76.69 17.31a6 6 0 11-6 6 6 6 0 016-6zM50 33.33A16.67 16.67 0 1066.67 50 16.68 16.68 0 0050 33.33zm0-9A25.68 25.68 0 1124.32 50 25.68 25.68 0 0150 24.32zM52.6 9h-5.2c-9.6 0-12.12.05-15.87.21l-1.73.09a27.74 27.74 0 00-9.29 1.7 15.56 15.56 0 00-5.75 3.74 15.47 15.47 0 00-3.68 5.61l-.1.26a27.64 27.64 0 00-1.67 9l-.09 1.89C9.06 35.37 9 37.92 9 48.11v4.49c0 9.6.05 12.12.21 15.87l.09 1.73a27.74 27.74 0 001.7 9.29 16.52 16.52 0 009.35 9.43l.25.1a27.84 27.84 0 009.2 1.68l1.86.08c3.77.16 6.38.21 16.45.21h4.49c11 0 12.7-.07 17.6-.29h.31a27.34 27.34 0 009-1.7 16.52 16.52 0 009.43-9.35l.1-.26a27.71 27.71 0 001.66-9.19l.08-1.86c.16-3.78.21-6.38.21-16.45V47.4c0-11-.07-12.7-.29-17.6v-.31a27.34 27.34 0 00-1.7-9 15.56 15.56 0 00-3.74-5.75 15.47 15.47 0 00-5.61-3.68l-.26-.1a27.64 27.64 0 00-9-1.67L68.5 9.2c-3.78-.14-6.3-.2-15.9-.2zm-3.79-9H54c10 0 11.86.08 16.61.3a36.77 36.77 0 0112.14 2.32 24.54 24.54 0 018.86 5.77 24.54 24.54 0 015.77 8.86l.09.24a36.79 36.79 0 012.22 11.69l.08 1.68c.18 4.21.23 6.78.23 18.34V53c0 10.87-.08 12.7-.3 17.61a36.77 36.77 0 01-2.32 12.14 25.58 25.58 0 01-14.63 14.63l-.24.09a36.79 36.79 0 01-11.69 2.22l-1.68.08c-4.21.18-6.78.23-18.34.23H47c-10.87 0-12.7-.08-17.62-.3a36.75 36.75 0 01-12.13-2.32 24.54 24.54 0 01-8.86-5.77 24.54 24.54 0 01-5.77-8.86l-.09-.24A36.79 36.79 0 01.31 70.82l-.07-1.53C.05 65 0 62.56 0 51.19V47c0-10.87.08-12.7.3-17.61a36.82 36.82 0 012.28-12v-.12a24.54 24.54 0 015.81-8.88 24.52 24.52 0 018.69-5.7l.17-.07A36.62 36.62 0 0129.18.31l1.53-.07C35 .05 37.44 0 48.81 0z"
      fill="#1d1d1b"
      fillRule="evenodd"
    />
  </svg>
);

export default styled(SvgInstagram16)``;
