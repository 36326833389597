import * as React from "react";
import styled from "styled-components";

const SvgLove30 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 100" {...props}>
    <path d="M50 0c16.569 0 30 13.266 30 29.63l-.007.37h.52c3.901 0 5.626.355 7.088 1.028l.617.308a9.086 9.086 0 013.78 3.78l.307.616c.673 1.462 1.028 3.187 1.028 7.087v40.514H100V90c0 5.523-4.477 10-10 10H10c-5.523 0-10-4.477-10-10v-6.667h6.667V42.82c0-3.9.355-5.625 1.028-7.087l.307-.616a9.086 9.086 0 013.78-3.78l.617-.308c1.357-.625 2.942-.976 6.284-1.023h1.32L20 29.63C20 13.266 33.431 0 50 0zm43.333 90H6.667a3.334 3.334 0 002.944 3.31l.389.023h80a3.334 3.334 0 003.31-2.944l.023-.389zM20.95 36.669h-2.084l-1.05.019-.827.04c-.73.05-1.187.134-1.555.262l-.344.142-.163.082c-.467.25-.795.578-1.045 1.046l-.082.162-.143.345c-.106.306-.182.675-.234 1.212l-.05.731-.03.934-.009 1.175v40.514h73.334L86.664 42.2l-.018-1.05-.04-.827c-.05-.73-.135-1.188-.262-1.555l-.143-.345-.082-.162a2.425 2.425 0 00-1.045-1.046l-.163-.082-.344-.142c-.307-.107-.675-.183-1.212-.235l-.732-.05-.933-.03-.555-.006H79.05C76.127 47.366 66.443 57.366 50 66.667c-16.443-9.301-26.127-19.3-29.051-29.998zm42.384 29.998a3.333 3.333 0 110 6.666H36.667a3.333 3.333 0 110-6.666h26.666zM50 6.667c-12.905 0-23.333 10.3-23.333 22.963 0 9.534 6.997 19.05 21.853 28.396l1.48.914.291-.176c15.29-9.349 22.678-18.873 23.03-28.418l.012-.716C73.333 16.966 62.905 6.667 50 6.667zM50 30a3.333 3.333 0 013.333 3.333V40a3.333 3.333 0 11-6.666 0v-6.667A3.333 3.333 0 0150 30zm0-10a3.333 3.333 0 110 6.667A3.333 3.333 0 0150 20z" />
  </svg>
);

export default styled(SvgLove30)``;
