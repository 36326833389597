import * as React from "react";
import styled from "styled-components";

const SvgExportStandard = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 100" {...props}>
    <path d="M44 14a2 2 0 010 4H16a2 2 0 010-4zM67.71 4h-6.24L76 18.53V10.16A7.24 7.24 0 0075.14 7 5.13 5.13 0 0073 4.86a7.24 7.24 0 00-3.16-.79h-.76L68.2 4zM58 6.19V15.57a7.67 7.67 0 00.71 3.24l.09.19a5.13 5.13 0 002.2 2.14 7.24 7.24 0 003.16.79h9.64zM32 22a2 2 0 010 4H16a2 2 0 010-4zm32 24a2 2 0 010 4h-6a2 2 0 010-4zm-32-3.83V52h9.83A12 12 0 0032 42.17zM64 54a2 2 0 010 4h-6a2 2 0 010-4zM28 42.17A12 12 0 1041.83 56H28zM30 38a16 16 0 11-16 16 16 16 0 0116-16zm58 28.82V70H62a2 2 0 00-.23 4H88v3.18L93.92 72zM64 82a2 2 0 010 4H16a2 2 0 010-4zM54 4H10.43A7.56 7.56 0 007 4.86 5.13 5.13 0 004.86 7a7.24 7.24 0 00-.79 3.16v79.41A7.56 7.56 0 004.86 93 5.13 5.13 0 007 95.14a7.24 7.24 0 003.16.79h59.73a7.24 7.24 0 003.11-.79A5.13 5.13 0 0075.14 93a7.24 7.24 0 00.79-3.16V78H62a6 6 0 010-12h14V26H65.67a14.42 14.42 0 01-5.88-1l-.45-.22-.22-.12a9.06 9.06 0 01-3.78-3.78l-.23-.44A12.84 12.84 0 0154 14.91v-1.14zm13.18-4c3.85 0 5.58.35 7 1l.45.22.22.12a9.06 9.06 0 013.78 3.78l.23.44c.65 1.35 1 2.87 1.1 6.11V66h4v-8l16 14L84 86v-8h-4v10.33a14.42 14.42 0 01-1 5.88l-.22.45-.12.22a9.06 9.06 0 01-3.78 3.78l-.44.23c-1.35.65-2.87 1-6.11 1.1H11.67a14.42 14.42 0 01-5.88-1l-.45-.22-.22-.12a9.06 9.06 0 01-3.78-3.78l-.23-.44A12.84 12.84 0 010 88.91V12.82c0-3.85.35-5.58 1-7l.22-.45.12-.22a9.06 9.06 0 013.78-3.81l.44-.23A12.84 12.84 0 0111.09 0h1.14z" />
  </svg>
);

export default styled(SvgExportStandard)``;
