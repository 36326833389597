import React from "react";
import styled from "styled-components";
import { fontFamily, fontSize, product_sans } from "../../lib/fonts";
const arrow = require("../../../assets/select-arrow.svg");

interface ISelectItem {
  value: string;
  label: string;
  labelMobile: string;
}

const SelectContainer = styled.select`
  background: transparent url(${arrow}) no-repeat right 20px center;
  appearance: none;
  border: 0;
  padding: 16.5px 38px 16.5px 20px;
  box-sizing: border-box;
  ${fontSize("15px")};
  ${fontFamily(product_sans.regular)};
  cursor: pointer;
  outline: none;
`;

export const Select = ({
  value: selectValue,
  items,
  onChange,
}: {
  value: React.ComponentProps<typeof SelectContainer>["value"];
  items: ISelectItem[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}) => (
  <SelectContainer onChange={onChange}>
    {items.map(({ label, value }, i) => (
      <option key={i} value={value} selected={value === selectValue}>
        {label}
      </option>
    ))}
  </SelectContainer>
);

export const SelectMobile = ({
  value: selectValue,
  items,
  onChange,
}: {
  value: React.ComponentProps<typeof SelectContainer>["value"];
  items: ISelectItem[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}) => (
  <SelectContainer onChange={onChange}>
    {items.map(({ labelMobile, value }, i) => (
      <option key={i} value={value} selected={value === selectValue}>
        {labelMobile}
      </option>
    ))}
  </SelectContainer>
);
