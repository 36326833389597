import * as React from "react";
import styled from "styled-components";

const SvgSeparator50 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 50 7" {...props}>
    <path
      d="M49 7a1 1 0 00.117-1.993L49 5c-.618 0-1.173-.268-2.039-1.019l-.32-.285-.348-.328-.381-.372-.666-.659-.427-.408C43.307.513 42.377 0 41 0c-1.377 0-2.307.513-3.819 1.929l-.427.408-.86.85-.365.35-.334.307C34.238 4.7 33.655 5 33 5c-.618 0-1.173-.268-2.039-1.019l-.32-.285-.348-.328-.381-.372-.666-.659-.427-.408C27.307.513 26.377 0 25 0c-1.377 0-2.307.513-3.819 1.929l-.427.408-.86.85-.365.35-.334.307C18.238 4.7 17.655 5 17 5c-.618 0-1.173-.268-2.039-1.019l-.32-.285-.348-.328-.381-.372-.666-.659-.427-.408C11.307.513 10.377 0 9 0 7.623 0 6.693.513 5.181 1.929l-.427.408-.86.85-.365.35-.334.307c-.893.799-1.461 1.113-2.065 1.152L1 5a1 1 0 100 2c1.377 0 2.307-.513 3.819-1.929l.427-.408.86-.85.365-.35.334-.307C7.762 2.3 8.345 2 9 2c.618 0 1.173.268 2.039 1.019l.32.285.348.328.381.372.666.659.427.408C14.693 6.487 15.623 7 17 7c1.377 0 2.307-.513 3.819-1.929l.427-.408.86-.85.365-.35.334-.307C23.762 2.3 24.345 2 25 2c.618 0 1.173.268 2.039 1.019l.32.285.348.328.381.372.666.659.427.408C30.693 6.487 31.623 7 33 7c1.377 0 2.307-.513 3.819-1.929l.427-.408.86-.85.365-.35.334-.307C39.762 2.3 40.345 2 41 2c.618 0 1.173.268 2.039 1.019l.32.285.348.328.381.372.666.659.427.408C46.693 6.487 47.623 7 49 7z"
      fill="#5E66D3"
    />
  </svg>
);

export default styled(SvgSeparator50)``;
