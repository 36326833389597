interface IFont {
  name: string;
  style?: string;
  eot?: string;
  woff?: string;
  woff2?: string;
  ttf?: string;
  svg?: string;
}
export function fontSize(
  valueInPX: string,
  { rootValue = 16, unitPrecision = 5, minPx = 1 } = {},
) {
  function toPx(value: string) {
    const parts = /^(\d+\.?\d*)([a-zA-Z%]*)$/.exec(value);
    if (!parts) {
      return 1;
    }

    const num = parts[1];
    const unit = parts[2];

    if (unit === "px" || unit === "") {
      return parseFloat(num);
    } else if (unit === "em" || unit === "rem") {
      return parseFloat(num) * 16;
    } else if (unit === "%") {
      return (parseFloat(num) / 100) * 16;
    }

    return 1;
  }

  function toFixed(num: number, precision: number) {
    const multiplier = Math.pow(10, precision + 1);
    const wholeNumber = Math.floor(num * multiplier);

    return (Math.round(wholeNumber / 10) * 10) / multiplier;
  }

  function pxReplace(strArg: string) {
    const str = parseFloat(strArg);

    if (minPx >= str) {
      return str + "px";
    }

    return toFixed(str / toPx(rootValue + ""), unitPrecision) + "rem";
  }

  if (!valueInPX) {
    return;
  }

  const pxRegExp = /(\d*\.?\d+)px/gi;
  const remValue = valueInPX.replace(pxRegExp, pxReplace);

  return `font-size: ${valueInPX};font-size: ${remValue};`;
}

export function fontFamily(font: IFont) {
  return `
    font-family: ${font.name}, sans-serif;
  `;
}

export function fontFace({ name, style, eot, woff, woff2, ttf, svg }: IFont) {
  const renderSrc = (url: string, type: string) => {
    let res = `url('${url}')`;

    if (type) {
      res += ` format('${type}')`;
    }

    return res;
  };

  const src = [
    eot && [`${eot}?#iefix`, "embedded-opentype"],
    woff && [`${woff}`, "woff"],
    woff2 && [`${woff2}`, "woff2"],
    ttf && [`${ttf}`, "truetype"],
    svg && [`${svg}#${svg}`, "svg"],
  ];

  return `
    @font-face {
      font-family: ${name};
      src: ${!eot ? "" : renderSrc(eot, "")};
      src: ${src
        .map(s => s && renderSrc(s[0], s[1]))
        .filter(Boolean)
        .join(",")};
      font-style: ${style};
    }
  `;
}
