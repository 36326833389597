import * as React from "react";
import styled from "styled-components";

const SvgLinkedin16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 100" {...props}>
    <path
      d="M25.45 17.8c-4.34 0-7.17 2.89-7.17 6.65s2.74 6.65 7 6.65c4.52 0 7.26-3 7.26-6.65s-2.75-6.65-7.09-6.65zm40.27 17.58a15.8 15.8 0 00-12.25 5.41l-.21.26-.21.26-.19.25-.09.13-.18.24c-.05.09-.11.17-.16.25l-.08.12h-.26l-.62-5.95H39.79l.09 2.12.12 1.79v38.86h13.59V54.46a9.36 9.36 0 01.41-3.33 7.36 7.36 0 016.9-5c4.49 0 6.5 3.2 6.78 8v24.99h13.53V53.84c0-12.59-6.64-18.46-15.49-18.46zm-33.63 1H18.63v42.74h13.46zM89.92 0A10 10 0 01100 10v80a10 10 0 01-10.08 10H10.08A10 10 0 010 90V10A10 10 0 0110.08 0z"
      fill="#1d1d1b"
      fillRule="evenodd"
    />
  </svg>
);

export default styled(SvgLinkedin16)``;
