import * as React from "react";
import styled from "styled-components";

const SvgScreen50 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 92" {...props}>
    <path d="M12.229.003L87.18 0c3.85 0 5.58.346 7.03 1.002l.45.217.223.117a9.086 9.086 0 013.78 3.78l.228.446c.652 1.35 1.028 2.866 1.097 6.11l.011 1.147v54.362l-.011 1.147c-.065 3.064-.404 4.586-.991 5.883l-.217.45-.117.223a9.086 9.086 0 01-3.78 3.78l-.446.228c-1.35.652-2.866 1.028-6.11 1.097L84 79.99V82c0 5.523-4.477 10-10 10H54c-5.523 0-10-4.477-10-10v-2H12.82l-1.148-.011c-3.064-.065-4.586-.404-5.883-.991l-.45-.217-.223-.117a9.086 9.086 0 01-3.78-3.78l-.228-.446c-.612-1.267-.98-2.68-1.08-5.529l-.025-1.138L0 12.82c0-3.85.346-5.58 1.002-7.03l.217-.45.117-.223a9.086 9.086 0 013.78-3.78l.446-.228c1.267-.612 2.68-.98 5.529-1.08l1.138-.025zM74 36H54c-.97 0-1.885.23-2.695.638L60 45.332V42a2 2 0 114 0v6a4 4 0 01-4 4h-6a2 2 0 110-4h3.332l-8.694-8.695a5.965 5.965 0 00-.628 2.342L48 42v40a6 6 0 005.647 5.99L54 88h20a6 6 0 005.99-5.647L80 82V42a6 6 0 00-5.647-5.99L74 36zM87.708 4.002H12.292l-1 .017-.858.036c-1.718.1-2.567.346-3.432.808a5.088 5.088 0 00-2.14 2.14c-.436.816-.679 1.618-.789 3.152l-.041.763-.024.882-.006.492v55.416l.017 1 .036.858c.1 1.718.346 2.567.808 3.432a5.088 5.088 0 002.14 2.14c.816.436 1.618.679 3.152.789l.763.041.882.024 1.02.008L44 75.996V42c0-2.08.635-4.012 1.722-5.612L36 26.666V30a2 2 0 11-4 0v-6a4 4 0 014-4h6a2 2 0 110 4h-3.334l9.722 9.722A9.954 9.954 0 0154 32h20c5.523 0 10 4.477 10 10v33.99l4.657-.008.425-.014.763-.041c1.534-.11 2.336-.353 3.153-.79a5.088 5.088 0 002.14-2.14c.436-.816.679-1.618.789-3.152l.041-.763.024-.882.008-1.02-.002-54.888-.016-.949-.014-.425-.041-.763c-.11-1.534-.353-2.336-.79-3.153a5.088 5.088 0 00-2.14-2.14c-.816-.436-1.618-.679-3.152-.789l-.763-.041-.882-.024-.492-.006zM22 8a2 2 0 110 4 2 2 0 010-4zm-6 0a2 2 0 110 4 2 2 0 010-4zm-6 0a2 2 0 110 4 2 2 0 010-4z" />
  </svg>
);

export default styled(SvgScreen50)``;
