import * as React from "react";
import styled from "styled-components";

const SvgPinterest16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 100" {...props}>
    <path
      d="M50 0a50 50 0 00-18.24 96.62c-.45-4-.83-10 .15-14.35.9-3.91 5.85-24.87 5.85-24.87a18.07 18.07 0 01-1.5-7.4c0-7 4.06-12.17 9-12.17 4.28 0 6.31 3.23 6.31 7.06 0 4.29-2.71 10.75-4.13 16.68-1.2 5 2.47 9 7.43 9 8.86 0 15.69-9.39 15.69-22.92 0-11.94-8.63-20.36-20.87-20.36C35.51 27.27 27.1 37.94 27.1 49c0 4.28 1.65 8.94 3.76 11.42a1.72 1.72 0 01.37 1.42c-.37 1.58-1.2 5-1.35 5.64-.23.9-.75 1.13-1.65.67-6.23-2.93-10.14-12-10.14-19.38 0-15.78 11.49-30.28 33-30.28 17.34 0 30.78 12.4 30.78 28.85.04 17.2-10.87 31.1-25.87 31.1-5 0-9.84-2.63-11.49-5.71 0 0-2.48 9.54-3.08 11.87-1.12 4.36-4.2 9.77-6.23 13.15A50.55 50.55 0 0050 100 50 50 0 1050 0z"
      fill="#1d1d1b"
    />
  </svg>
);

export default styled(SvgPinterest16)``;
