import * as React from "react";
import styled from "styled-components";

const SvgShape30 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 30 15" {...props}>
    <path
      d="M15 13c7.18 0 13-5.82 13-13h2c0 8.284-6.716 15-15 15C6.716 15 0 8.284 0 0h2c0 7.18 5.82 13 13 13z"
      fill="#FF80AF"
    />
  </svg>
);

export default styled(SvgShape30)``;
