import * as React from "react";
import styled from "styled-components";

const SvgArrowR16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 75" {...props}>
    <path d="M72.63 1.76l.5.59L100 37.5 73.13 72.65a5.78 5.78 0 01-8.39 1 6.44 6.44 0 01-1.36-8.14l.43-.64 16.12-21.1H6.25a6.25 6.25 0 010-12.5h73.68L63.81 10.15a6.46 6.46 0 01.38-8.25l.55-.53a5.78 5.78 0 017.89.39z" />
  </svg>
);

export default styled(SvgArrowR16)``;
