import * as React from "react";
import styled from "styled-components";

const SvgArrowRight14 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 57" {...props}>
    <path d="M76.23 1.55l.69.61L97.85 23a7.34 7.34 0 012.15 4.81v1.38A7.34 7.34 0 0197.85 34L76.92 54.84A7.39 7.39 0 1166.46 44.4l8.81-8.77H7.14a7.13 7.13 0 110-14.25h68.13l-8.81-8.78a7.36 7.36 0 01-.61-9.74l.61-.7a7.42 7.42 0 019.77-.61z" />
  </svg>
);

export default styled(SvgArrowRight14)``;
