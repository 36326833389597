import * as React from "react";
import styled from "styled-components";

const SvgFilter16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 62.5" {...props}>
    <path
      d="M93.75 0a6.25 6.25 0 010 12.5H6.25a6.25 6.25 0 010-12.5zM75 25a6.25 6.25 0 010 12.5H25A6.25 6.25 0 0125 25zM56.25 50a6.25 6.25 0 010 12.5h-12.5a6.25 6.25 0 010-12.5z"
      fill="#1d1d1b"
      fillRule="evenodd"
    />
  </svg>
);

export default styled(SvgFilter16)``;
