import * as React from "react";
import styled from "styled-components";

const SvgMail30 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 80" {...props}>
    <path
      d="M50 20a13.36 13.36 0 019.91 4.41 3.29 3.29 0 01-4.6 4.69l-.31-.29a6.77 6.77 0 00-11.75 4v13.87a6.75 6.75 0 0013.48.51v-5.51a1.73 1.73 0 00-3.43-.31v5.31a3.29 3.29 0 01-6.56.39v-5.39a8.31 8.31 0 0116.61-.54v5.55a13.33 13.33 0 01-26.65.67v-14A13.34 13.34 0 0150 20zm0-13.33a345.38 345.38 0 00-35.67 2.1l-.52.08L29 24a3.49 3.49 0 01.29 4.62L29 29a3.49 3.49 0 01-4.62.29L24 29 8.61 13.57l-.1.42-.09.53-.34 2.75A197.08 197.08 0 006.67 40a201.71 201.71 0 001.74 25.44 7.88 7.88 0 00.2 1L24 51a3.5 3.5 0 115 5L13.81 71.15l.5.07 2.69.28q18.39 1.83 33 1.83a345.38 345.38 0 0035.67-2.1l.52-.08L71 56a3.49 3.49 0 01-.29-4.62L71 51a3.49 3.49 0 014.62-.29L76 51l15.41 15.4.1-.42.09-.53.34-2.75A197.08 197.08 0 0093.33 40a201.71 201.71 0 00-1.74-25.44 7.88 7.88 0 00-.2-1L76 29a3.5 3.5 0 11-5-5L86.19 8.85l-.5-.07L83 8.5Q64.63 6.67 50 6.67zM50 0q15 0 33.68 1.87l2.69.27A13.33 13.33 0 0198.2 13.71 207.49 207.49 0 01100 40a203.47 203.47 0 01-1.46 23.5l-.34 2.79a13.33 13.33 0 01-11.83 11.57A350.23 350.23 0 0150 80q-15 0-33.68-1.87l-2.69-.27A13.33 13.33 0 011.8 66.29 207.49 207.49 0 010 40a203.47 203.47 0 011.46-23.5l.34-2.79A13.33 13.33 0 0113.63 2.14 350.23 350.23 0 0150 0z"
      fillRule="evenodd"
    />
  </svg>
);

export default styled(SvgMail30)``;
