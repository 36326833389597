import * as React from "react";
import styled from "styled-components";

const SvgCross20 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 100" {...props}>
    <path d="M31.41 31.41a4.79 4.79 0 016.79 0L50 43.2l11.8-11.79a4.8 4.8 0 016.79 6.79L56.8 50l11.79 11.8a4.8 4.8 0 01.4 6.34l-.4.45a4.79 4.79 0 01-6.79 0L50 56.8 38.2 68.59a4.8 4.8 0 01-6.79-6.79L43.2 50 31.41 38.2a4.8 4.8 0 01-.4-6.34zM50 10a40 40 0 1040 40 40 40 0 00-40-40zm0-10A50 50 0 110 50 50 50 0 0150 0z" />
  </svg>
);

export default styled(SvgCross20)``;
