import * as React from "react";
import styled from "styled-components";

const SvgLaptop50 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 72" {...props}>
    <path d="M50 8a2 2 0 11-2 2 2 2 0 012-2zm29.18-4h-59.5c-3 .08-4.32.4-5.65 1.11A6.88 6.88 0 0011.13 8c-.8 1.52-1.13 3-1.13 6.82V60h80V15.38c0-4.08-.27-5.65-1-7.14L88.87 8A6.88 6.88 0 0086 5.13C84.48 4.33 83 4 79.18 4zm15.75 60H5.07a8 8 0 006.53 4H88a8 8 0 006.68-3.59zM20.76 0h57.86c5.38 0 7.29.56 9.24 1.6a10.92 10.92 0 014.54 4.54c1 2 1.6 3.89 1.6 9.24V60h6a12 12 0 01-12 12H12A12 12 0 010 60h6V15.38c0-5.38.56-7.29 1.6-9.24a10.92 10.92 0 014.54-4.54l.49-.25C14.36.51 16.3 0 20.76 0z" />
  </svg>
);

export default styled(SvgLaptop50)``;
