import * as React from "react";
import styled from "styled-components";

const SvgOffer30 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 80 100" {...props}>
    <path d="M46.67 26.67a6.67 6.67 0 106.66 6.66 6.66 6.66 0 00-6.66-6.66zm0-6.67a13.34 13.34 0 11-13.34 13.33A13.33 13.33 0 0146.67 20zm10 36.67a3.33 3.33 0 010 6.66h-20a3.33 3.33 0 110-6.66zM10 6.67a3.32 3.32 0 00-3.31 2.94v63.72h6.66V10a3.32 3.32 0 00-2.94-3.31zM56.67 70a3.34 3.34 0 010 6.67h-20a3.34 3.34 0 110-6.67zM70 6.67H19.43A9.91 9.91 0 0120 10v82.55l6.67-3.34 6.66 3.34L40 89.21l6.67 3.34 6.66-3.34L60 92.55l6.67-3.34 6.66 3.34V10a3.32 3.32 0 00-2.94-3.31zM70 0a10 10 0 0110 10v90h-6.67l-6.66-3.33L60 100l-6.67-3.33-6.66 3.33L40 96.67 33.33 100l-6.66-3.33L20 100h-6.67V80H0V10A10 10 0 0110 0z" />
  </svg>
);

export default styled(SvgOffer30)``;
