import * as React from "react";
import styled from "styled-components";

const SvgArrowB6 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 50" {...props}>
    <path fill="#1d1d1b" fillRule="evenodd" d="M0 0h100L50 50 0 0z" />
  </svg>
);

export default styled(SvgArrowB6)``;
