import { css } from "styled-components";
import { fontSize, fontFamily } from "./cssUtils";
import { mediaQuery } from "react-styled-mediaquery";
import { product_sans } from "./fonts";

export const colors = {
  primary: "#19A0FF",
  primaryLt: "#E5F1FA",
  primaryLter: "#EEF5FA",

  secondary: "#3F3940",

  action: "#FF9085",

  green: "#A3D96C",
  red: "#E62E65",

  greyDrk: "#262D4D",
  grey: "#A4A2A6",
  greyLt: "#F7F6FB",

  greyFooter: "#B8BBCC",
};

export const theme = {
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 2, // rem
    outerMargin: 1, // rem
    mediaQuery: "only screen",
    container: {
      sm: 46, // rem
      md: 61, // rem
      lg: 76, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48.0625, // em
      md: 64, // em
      lg: 75, // em
    },
  },
};

export const colorThemes = {
  titleHeaderStyle: css`
    color: ${colors.greyDrk};
    ${fontSize("40px")};
    line-height: 45px;

    ${mediaQuery("<=", "tablet")`
      ${fontSize("24px")};
      line-height: 30px;
    `}
  `,
  titleBodyStyle: css`
    color: ${colors.greyDrk};
    ${fontSize("30px")};
    line-height: 40px;
    margin-bottom: 20px;
    ${fontFamily(product_sans.bold)}

    ${mediaQuery("<=", "tablet")`
      ${fontSize("20px")};
      line-height: 28px;
    `}
  `,
  titleBodyBigStyle: css`
    color: ${colors.greyDrk};
    ${fontSize("40px")};
    line-height: 48px;
    margin-bottom: 20px;
    ${fontFamily(product_sans.bold)}

    ${mediaQuery("<=", "tablet")`
      ${fontSize("20px")};
      line-height: 28px;
    `}
  `,
  titleTableStyle: css`
    color: ${colors.secondary};
    ${fontFamily(product_sans.medium)}
    text-transform: uppercase;
    ${fontSize("11px")}
    line-height: 15px;
    letter-spacing: 0.5px;
  `,
};
