import * as React from "react";
import styled from "styled-components";

const SvgMobile42 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 62 100" {...props}>
    <path d="M33.38 4.76h-4.76v2.38a2.39 2.39 0 002.1 2.37H31a2.39 2.39 0 002.37-2.1V4.76zm11.93 0h-7.16v2.38a7.15 7.15 0 11-14.3 0V4.76h-7.16c-3.59.09-5.53.5-7.12 1.35a8.23 8.23 0 00-3.45 3.44c-1 1.78-1.32 3.51-1.35 8.09v62.59l.12.18a11.92 11.92 0 009.35 5.29h32.95a11.92 11.92 0 009.59-4.83l.33-.47.12-.18V18.31c0-5.08-.35-6.89-1.35-8.76a8.23 8.23 0 00-3.45-3.44l-.68-.36c-1.38-.69-2.48-.96-6.44-.99zM5.08 87.35a9.42 9.42 0 001 3.1 8.23 8.23 0 003.45 3.44c1.78 1 3.51 1.31 8.1 1.35h26c5.09 0 6.91-.35 8.77-1.35a8.23 8.23 0 003.45-3.44 9.42 9.42 0 001-3.1l-.47.33a16.76 16.76 0 01-8.62 2.78H14.81a16.67 16.67 0 01-9.73-3.11zM17.6 0h26.06c6.38 0 8.69.66 11 1.91a13 13 0 015.41 5.4C61.34 9.64 62 12 62 18.31v64.83c-.1 5.28-.75 7.41-1.9 9.55a13 13 0 01-5.41 5.4C52.35 99.34 50 100 43.66 100H16.89c-5.3-.1-7.43-.75-9.57-1.9a13 13 0 01-5.41-5.4l-.3-.58C.6 90 .05 87.73 0 82.43V18.31c0-6.36.66-8.67 1.91-11a13 13 0 015.41-5.4l.59-.3C10 .6 12.28.05 17.6 0z" />
  </svg>
);

export default styled(SvgMobile42)``;
