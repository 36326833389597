import * as React from "react";
import styled from "styled-components";

const SvgScanner30 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 100" {...props}>
    <path d="M86.67 0h.66A13.32 13.32 0 01100 12.65v11.07a3.33 3.33 0 01-6.62 0V12.84a6.68 6.68 0 00-6.13-6.15H76.34a3.33 3.33 0 010-6.62h.39zM23.33 0a3.33 3.33 0 01.39 6.64H13.33a6.68 6.68 0 00-6.65 6.17v10.52a3.34 3.34 0 01-6.68.39V13.33A13.32 13.32 0 0112.67 0h.66zm53.34 20H23.33A3.33 3.33 0 0020 22.94v23.73h60V23.33A3.33 3.33 0 0077.06 20zM3.33 53.33a3.33 3.33 0 110-6.66h10V23.33a10 10 0 0110-10h53.34a10 10 0 0110 10v23.34h10a3.33 3.33 0 110 6.66zM20 60v16.67A3.33 3.33 0 0022.94 80H76.67A3.33 3.33 0 0080 77.06V60h6.67v16.67a10 10 0 01-10 10H23.33a10 10 0 01-10-10V60zm76.67 13.33a3.33 3.33 0 013.31 3v10.39A13.32 13.32 0 0187.33 100H76.67a3.33 3.33 0 01-.39-6.64h10.39a6.68 6.68 0 006.65-6.17V76.67a3.35 3.35 0 013.35-3.34zm-93.34 0a3.33 3.33 0 013.31 3v10.83a6.68 6.68 0 006.13 6.15h10.91a3.33 3.33 0 010 6.62H12.63A13.32 13.32 0 010 87.35V76.28a3.33 3.33 0 013.33-2.95z" />
  </svg>
);

export default styled(SvgScanner30)``;
