import * as React from "react";
import styled from "styled-components";

const SvgMessage30 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 100" {...props}>
    <path d="M43.33 30A3.34 3.34 0 1140 33.33 3.34 3.34 0 0143.33 30zm-10 0A3.34 3.34 0 1130 33.33 3.34 3.34 0 0133.33 30zm-10 0A3.34 3.34 0 1120 33.33 3.34 3.34 0 0123.33 30zm10-23.33a26.67 26.67 0 00-22.6 40.82l.58.89 2 2.93L9.84 60h24.24a26.67 26.67 0 00-.75-53.32zm0-6.67a33.33 33.33 0 01.94 66.65H0l5.81-14.51A33.34 33.34 0 0133.33 0zm53.34 40A13.29 13.29 0 01100 53.25v40.13a6.68 6.68 0 01-9 6.2l-17.67-6.25H46.67a13.58 13.58 0 01-13.34-13.58v-6.42a3.34 3.34 0 016.67 0v6.42a7 7 0 006.18 6.9H74.48l18.85 6.66V53.25a6.63 6.63 0 00-6.16-6.57H73.33a3.34 3.34 0 010-6.67z" />
  </svg>
);

export default styled(SvgMessage30)``;
