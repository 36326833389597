import * as React from "react";
import styled from "styled-components";

const SvgArrowL16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 60 100" {...props}>
    <path
      d="M3 57.84l39.12 39.1a10.45 10.45 0 0014.82-14.78L24.76 50l32.18-32.16A10.45 10.45 0 0057.81 4l-.87-1a10.45 10.45 0 00-13.8-.87l-1 .87L3 42.16a10.42 10.42 0 00-3 6.78v2.12a10.42 10.42 0 003 6.78z"
      fill="#1d1d1b"
      fillRule="evenodd"
    />
  </svg>
);

export default styled(SvgArrowL16)``;
