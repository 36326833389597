import * as React from "react";
import styled from "styled-components";

const SvgCheck20 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 100" {...props}>
    <path d="M73.48 31.52a5.16 5.16 0 010 7.32L48.84 68.48A5.13 5.13 0 0145.48 70h-1a5.16 5.16 0 01-2.87-1.08l-.49-.43-14.6-14.65a5.18 5.18 0 016.83-7.75l.49.43 10.84 10.83 21.48-25.83a5.16 5.16 0 017.32 0zM50 10a40 40 0 1040 40 40 40 0 00-40-40zm0-10A50 50 0 110 50 50 50 0 0150 0z" />
  </svg>
);

export default styled(SvgCheck20)``;
