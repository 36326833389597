import * as React from "react";
import styled from "styled-components";

const SvgWhatsapp16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 100" {...props}>
    <path
      d="M85.43 14.53a49.84 49.84 0 00-85 35 49.27 49.27 0 006.63 24.79L0 100l26.39-6.89a49.93 49.93 0 0023.8 6A49.74 49.74 0 00100 49.59a49.17 49.17 0 00-14.57-35.06M50.21 90.77A41.46 41.46 0 0129.13 85l-1.51-.89L12 88.23 16.14 73l-1-1.56A41.17 41.17 0 0150.23 8.37a41.2 41.2 0 110 82.4m22.7-30.84c-1.24-.62-7.36-3.62-8.5-4s-2-.62-2.8.62-3.21 4-3.94 4.85-1.45.93-2.7.31a34.18 34.18 0 01-10-6.14A37.26 37.26 0 0138.05 47c-.73-1.24-.08-1.91.54-2.53s1.25-1.44 1.87-2.17a8.37 8.37 0 001.24-2.1 2.26 2.26 0 00-.1-2.2c-.31-.62-2.8-6.72-3.84-9.2s-2-2.08-2.79-2.12-1.56 0-2.39 0a4.6 4.6 0 00-3.32 1.54 13.87 13.87 0 00-4.35 10.33c0 6.1 4.46 12 5.08 12.81s8.77 13.33 21.25 18.7a74 74 0 007.09 2.61 17.17 17.17 0 007.84.49c2.39-.36 7.36-3 8.4-5.89s1-5.37.72-5.89-1.14-.82-2.38-1.44"
      fill="#1d1d1b"
      fillRule="evenodd"
    />
  </svg>
);

export default styled(SvgWhatsapp16)``;
