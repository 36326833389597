import * as React from "react";
import styled from "styled-components";

const SvgViadeo16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 87.5 100" {...props}>
    <path
      d="M81.89 2.78l.41-.72.38.58.33.54a32.69 32.69 0 013.33 8 32.23 32.23 0 011.09 10.7 31.47 31.47 0 01-1.68 8.42 20.48 20.48 0 01-1.83 3.73 21.62 21.62 0 01-4.52 5.52 13.58 13.58 0 01-6.57 3.13 12.46 12.46 0 01-6-.46 17.27 17.27 0 01-5.61-3.19l-.43-.38-.88-.78a1.06 1.06 0 01.46-.39l.25-.13a92.63 92.63 0 009.46-6.19A48.6 48.6 0 0077 25a19.55 19.55 0 003.08-4.36 10.17 10.17 0 00.82-2.28v-.59a35.58 35.58 0 01-23.28 17.42l-.23-.29v-.08a16.2 16.2 0 01-2.12-7.23A12.42 12.42 0 0158 19.13a12.89 12.89 0 013.81-3.27 31.6 31.6 0 016.87-2.81l.85-.22a18.24 18.24 0 008.39-4.7A20.19 20.19 0 0081.77 3zM40.29.17V0l.5.39.21.18a31 31 0 015.86 6.77 42.87 42.87 0 015.29 11.75A54.3 54.3 0 0154 28.45v.33l.13.9.18.36.09.19a63.46 63.46 0 013.14 11.23 52 52 0 01.83 10.2 46.42 46.42 0 01-4.93 20 53.47 53.47 0 01-16 19l-1 .74v.18h1.87A25.93 25.93 0 0055 83.75a28.06 28.06 0 007.79-19.86 28.13 28.13 0 00-1.67-9.54l-.28-.71c0-.12-.09-.25-.13-.37v-.19l1.91-.54.95-.3c.93-.32 1.84-.68 2.72-1.08.46-.21.91-.43 1.36-.66l1.35-.71c.08.16.17.31.24.48a33.6 33.6 0 012 7.23 40 40 0 01.47 8.81 35.19 35.19 0 01-7.19 19.58 37 37 0 01-7.73 7.73A32.47 32.47 0 0144 99.2a41.06 41.06 0 01-10.43.74 35.77 35.77 0 01-11-2.21 31.67 31.67 0 01-10.21-6.22A37.86 37.86 0 013 78.58a34.42 34.42 0 01-2.68-9.64 38.46 38.46 0 01-.26-7.11A35.52 35.52 0 018.86 40a36.47 36.47 0 018.45-7.3 32.8 32.8 0 0110.94-4.3 39.4 39.4 0 019.88-.67 38 38 0 018.22 1.35l1.32.4a1.89 1.89 0 01.64.31 33.15 33.15 0 00-1.69 4 23.35 23.35 0 00-.94 4.32l-.52-.11-.16-.06a24 24 0 00-6.79-1.51 28.66 28.66 0 00-5.48.08 25 25 0 00-14.07 6.31 28.66 28.66 0 00-7.28 9.68 27.14 27.14 0 00-2.18 7.9 30.21 30.21 0 00-.09 6.72 28 28 0 003.36 10.78 25.84 25.84 0 0018.08 13.2 1.7 1.7 0 001-.1 25.76 25.76 0 008.1-5.28A34.45 34.45 0 0046 77.33a55.68 55.68 0 004.46-11A83.35 83.35 0 0052.6 57c.43-2.52.76-5 1-7.6a133 133 0 00.6-16.58v-.58l-.11-2.52-.12-.9-.24-.42-1.56-4-.81-2A168.61 168.61 0 0040.81 1.16c-.17-.29-.35-.58-.51-.88a.13.13 0 01-.01-.11z"
      fill="#1d1d1b"
    />
  </svg>
);

export default styled(SvgViadeo16)``;
